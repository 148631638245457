import { memo, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'

import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { useNavigate } from 'react-router-dom'

import { withRouter } from '../wrappers/routeWrappers'

import Settings from './Settings/Settings'
import ChangeStore from './Settings/ChangeStore'

import countrLogo from '../assets/countr_logo_white.png'

import './../styles/Menu.scss'

const mapStateToProps = state => {
  return {
    app: state.app,
    store: state.store.store,
    allStores: state.store.allStoresAssigned,
    device: state.device.device
  }
}

const APP_VERSION = process.env.REACT_APP_VERSION

const Menu = connect(mapStateToProps)(
  memo(function Menu(props) {
    const countrMenu = useRef(null)
    const menuBackdrop = useRef(null)
    const [showSettings, setShowSettings] = useState(false)
    const [showHotKyes, setShowHotKyes] = useState(false)
    const [showChangeStore, setShowChangeStore] = useState(false)

    const navigate = useNavigate()

    const closeNav = () => {
      countrMenu.current.style.left = '-300px'
      menuBackdrop.current.style.display = 'none'
    }

    const goTo = route => {
      navigate(route)
      closeNav()
    }

    const logout = () => {
      closeNav()
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('token_type')
      localStorage.removeItem('rememberPassword')
      localStorage.removeItem('user')
      localStorage.removeItem('device')
      localStorage.removeItem('KDS:SelectedStore')
      navigate('/')
      window.location.reload()
    }

    const openClose = type => {
      switch (type) {
        case 'settings':
          setShowSettings(!showSettings)
          break
        case 'hotkeys':
          setShowHotKyes(!showHotKyes)
          break
        case 'store':
          setShowChangeStore(!showChangeStore)
          break
        default:
          break
      }
    }

    const returnStoresName = () => {
      return props.allStores.map(s => s.name).join(', ')
    }

    let storeLogo = countrLogo
    if (props.store.store_logo && props.store.store_logo.length) {
      storeLogo = props.store.store_logo
    }

    return (
      <div>
        <div
          id="sidenav-backdrop"
          className="sidenav-backdrop"
          onClick={closeNav}
          ref={menuBackdrop}
        />
        <div id="countr-menu" className="sidenav" ref={countrMenu}>
          <div className="sidenav-content">
            <header className="main-menu-header">
              <div>
                <img width="280" alt="countr-logo" src={storeLogo} />
              </div>
              <div className="main-menu-app-version">
                <Text id="app_version" />: {APP_VERSION}
              </div>
              <div className="main-menu-header-divider" />
              <div className="menu-userinfo">
                <p>
                  <Icon>account_circle</Icon>
                  {props.app.user.username}
                </p>
                <p
                  className="clickable highlight"
                  onClick={() => openClose('store')}>
                  <Icon>store</Icon>
                  {returnStoresName()}
                </p>
              </div>
            </header>
            <List component="nav" className="main-menu">
              <ListItem button divider={true} onClick={() => goTo('/main')}>
                <Icon>dashboard</Icon>
                <ListItemText primary="KDS" />
              </ListItem>
              <ListItem
                button
                divider={true}
                onClick={() => goTo('/completed')}>
                <Icon>all_inbox</Icon>
                <ListItemText primary={<Text id="completed" />} />
              </ListItem>
              {/* <ListItem button divider={true} onClick={() => goTo('/cancelled')}>
                <Icon>cancelled</Icon>
                <ListItemText primary={<Text id="cancelled" />} />
              </ListItem> */}
              <ListItem
                button
                divider={true}
                onClick={() => openClose('settings')}>
                <Icon>settings</Icon>
                <ListItemText primary={<Text id="settings" />} />
              </ListItem>

              <ListItem
                className="logout-btn"
                button
                divider={true}
                onClick={logout}>
                <Icon>logout</Icon>
                <ListItemText primary={<Text id="sign_out" />} />
              </ListItem>
            </List>
          </div>
        </div>
        {showSettings && (
          <Settings open={showSettings} handleClose={openClose} />
        )}
        {showChangeStore && (
          <ChangeStore open={showChangeStore} handleClose={openClose} />
        )}
      </div>
    )
  })
)

export default withRouter(Menu)
