import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    background: {
      paper: '#fff',
      default: 'rgba(238, 238, 238, 1)'
    },
    primary: {
      light: 'rgba(74, 77, 82, 1)',
      main: 'rgba(29, 33, 39, 1)',
      dark: 'rgba(20, 23, 27, 1)',
      contrastText: '#fff'
    },
    secondary: {
      light: 'rgba(90, 164, 221, 1)',
      main: 'rgba(49, 142, 213, 1)',
      dark: 'rgba(34, 99, 149, 1)',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    }
  },
  typography: {
    useNextVariants: true
  }
})
