import { memo, useState } from 'react';
import { translate } from 'react-internationalization'

import CartUtils from '../../utils/CartUtils'
import Utils from '../../utils/Utils'

const STATUS = {
  POSTPONED: 'postponed',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
}

const StatusInfoCart = memo(({ cart, type }) => {
  const [showCartDetails, setShowCartDetails] = useState(false)

  const returnCartStyle = () => {
    switch (type) {
      case STATUS.POSTPONED:
        return 'cart-wrapper__postponed'
      case STATUS.COMPLETED:
        return 'cart-wrapper__completed'
      case STATUS.CANCELLED:
        return 'cart-wrapper__danger_delivery'
      default:
        return 'cart-wrapper__completed'
    }
  }

  return (
    <div>
      <div className={`${cart._id} cart-tools-wrapper-backdrop`} />
      <article className="cart-wrapper">
        <header
          className={returnCartStyle()}
          style={{
            cursor: 'pointer',
            borderBottomLeftRadius: showCartDetails ? 0 : 10,
            borderBottomRightRadius: showCartDetails ? 0 : 10
          }}
          onClick={() => setShowCartDetails(!showCartDetails)}>
          <div className="left">
            <h2>#{CartUtils.getCartName(cart)}</h2>
            <p>{CartUtils.getDate(CartUtils.getPropertyTimeValue(cart))}</p>
            <p>{CartUtils.getTime(CartUtils.getPropertyTimeValue(cart))}</p>
          </div>
          <div className="right">
            <p>Buzzer: {cart.extras.buzzer}</p>
          </div>
          <div className="right">
            <p>{translate(cart.status)}</p>
          </div>
        </header>

        {showCartDetails && (
          <section className="cart-items has-footer">
            {cart.items.map((item, i) => {
              return (
                <div className="item" key={`${item.product._id}_${i}`}>
                  <div className="left">
                    <h1>
                      {item.amount}x {item.product.name}
                      {(!item.product.current_variant.default)
                        && item.product.current_variant.name !== item.product.name
                          ? ` (${item.product.current_variant.name})`
                          : ''}
                    </h1>
                    {CartUtils.itemHasAddons(item) && (
                      <div>
                        {item.product.current_addons.map(addon => (
                          <p
                            className="cart-wrapper-addons"
                            key={`${i}_${addon._id}`}>
                            {addon.amount > 1
                              ? `${addon.amount}x`
                              : `+ ${addon.name}`}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                  {CartUtils.itemStatusHasEmployee(
                    item.status[item.status.length - 1]
                  ) && (
                    <div className="right">
                      <div>
                        <span
                          className={`cart-wrapper-employee-status_${
                            item.status[item.status.length - 1].state
                          }`}
                        />
                        <span className="cart-wrapper-employee">
                          {CartUtils.getItemStatusEmployee(
                            item.status[item.status.length - 1]
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </section>
        )}

        {showCartDetails && (
          <footer className={returnCartStyle()}>
            {cart.customer && (
              <div className="right">
                <p>
                  <strong>{`${Utils.printField(
                    cart.customer,
                    'first_name'
                  )} ${Utils.printField(cart.customer, 'last_name')}`}</strong>
                </p>
                <p>
                  <strong>{`${Utils.printField(
                    cart.customer,
                    'phone'
                  )}`}</strong>
                </p>
              </div>
            )}
          </footer>
        )}
      </article>
    </div>
  )
})

export default StatusInfoCart
