import {
  SET_DEVICE,
  SET_DEVICE_SETTINGS,
  SET_DEVICES
} from '../actions/actionTypes'

const initialState = {
  device: {},
  devices: []
}

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICE:
      return {
        ...state,
        device: action.payload
      }
    case SET_DEVICE_SETTINGS:
      return {
        ...state,
        device: {
          ...state.device,
          settings: {
            ...state.device.settings,
            web_settings: {
              ...state.device.settings.web_settings,
              kitchen_categories: action.payload
            }
          }
        }
      }
    case SET_DEVICES:
      return {
        ...state,
        devices: action.payload
      }
    default:
      return state
  }
}

export default deviceReducer
