import { createRef } from 'react';
import { connect } from 'react-redux'
import { translate } from 'react-internationalization'

const mapStateToProps = state => {
  return {
    employees: state.store.employees
  }
}

const EmployeesDropdown = props => {
  const selectRef = createRef()

  const handleChangeEmployee = () => {
    props.setEmployee(selectRef.current.value)
  }

  return (
    <select
      className="employeesDropdown"
      onChange={evt => handleChangeEmployee(evt)}
      ref={selectRef}
      value={props.selectedEmployee?._id || ''}>
      <option value="">{translate('select_employee')}</option>

      {props.employees.map((emp, i) => (
        <option key={`${props.cartId}_${emp._id}_${i}`} value={emp._id}>
          {emp.name}
        </option>
      ))}
    </select>
  )
}

export default connect(mapStateToProps, null)(EmployeesDropdown)
