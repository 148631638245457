function ProductMedia({ medias, options }) {
  const { width, height } = options
  const display = medias.map((media, i) => {
    const { type, title, description, url } = media
    const attr = {
      className: `countr-product-medias countr-media-${type}`,
      key: `media_${i}_${type}`
    }
    switch (type) {
      case 'image':
        return (
          <figure {...attr}>
            <figcaption>{title}</figcaption>
            <img
              width={width || '100%'}
              height={height || '100%'}
              src={url}
              alt={description}
            />
          </figure>
        )

      case 'video':
        return (
          <div {...attr}>
            <h3>{title}</h3>
            <video
              controls
              src={url}
              preload="metadata"
              width={width || '100%'}
              key={`media_${i}_${type}`}>
              Please use Chrome Browser to watch the video
            </video>
            <small>{description}</small>
          </div>
        )

      case 'pdf':
        return (
          <div {...attr}>
            <p>
              <a target="_blank" href={url} download rel="noreferrer">
                {title}
              </a>
            </p>
            <small>{description}</small>
          </div>
        )

      default:
        throw new Error(`Type ${type} of Media is Invalid`)
    }
  })

  return display
}

export default ProductMedia
