import { memo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { translate } from 'react-internationalization'

import CartUtils from '../../utils/CartUtils'
import EmployeesDropdown from './EmployeesDropdown'
import { AppInstances } from '../../utils/CountrSdk'

import StatusButtons from '../Shared/StatusButtons'

import './../../styles/Tools.scss'

const mapStateToProps = state => {
  return {
    employees: state.store.employees,
    carts: state.carts.list,
    device: state.device.device,
    store: state.store.store,
    hide_completed_items: state.settings.hide_completed_items.value
  }
}

const GeneralTools = memo(props => {
  const { cart, updateTransaction } = props
  const [selectedEmployee, setEmployee] = useState({})

  const settingsKitchenCategories =
    props.device?.settings?.web_settings?.kitchen_categories || []

  const hideCompletedSetting = useSelector(() => {
    return props.device.settings?.hide_completed_items?.value || false
  })

  const updateStatus = async status => {
    const emp = props.employees.find(e => e._id === selectedEmployee)
    const employee = {}

    if (emp) {
      employee._id = emp._id
      employee.name = emp.name
    }

    props.cart.items = props.cart.items.map(item => {
      CartUtils.moveGeneralCartStatus(
        item,
        status,
        employee,
        hideCompletedSetting,
        settingsKitchenCategories
      )

      return item
    })

    // Change the Device for the updated Cart
    props.cart.device = props.device._id
    props.cart.status = status

    updateTransaction(props.cart, 'general')

    if (
      (props.store?.options?.printDevice ||
        localStorage.getItem('localDesktop')) &&
      status === 'ready'
    ) {
      const countr = await AppInstances.getCountrSdk()
      const type = `${String(props.cart.__t).toLowerCase()}s`
      countr[type].print(props.cart._id, {
        device:
          localStorage.getItem('localDesktop') ||
          props.store?.options?.printDevice,
        status: status,
        item: 'all'
      })
    }
  }

  return (
    <div className="tools">
      <div className="tools-header">#{cart.receipt_id}</div>
      <div className="tools-sub-header">
        ({translate('send_all_to_status')})
      </div>
      <div className="btn-list">
        {!!props.employees.length && (
          <EmployeesDropdown
            employees
            setEmployee={setEmployee}
            cartId={cart._id}
            selectedEmployee={cart?.employee}
          />
        )}
        <div className="tool-status-grid">
          <StatusButtons updateStatus={updateStatus} showCancel />
        </div>
      </div>
    </div>
  )
})

export default connect(mapStateToProps, null)(GeneralTools)
