import CountrClient from '@countr/sdk'
import countrLog from '@countr/slack'

/**
 * Creating a instance for Countr SDK
 */
export const AppInstances = (function () {
  var instance

  async function createCountrSdk() {
    const {
      REACT_APP_PROD,
      REACT_APP_STAGING,
      REACT_APP_CLIENT_ID,
      REACT_APP_CLIENT_SECRET
    } = process.env

    const countrClient = new CountrClient({
      opts: {
        staging: REACT_APP_PROD === 'false' && REACT_APP_STAGING === 'true',
        local: REACT_APP_PROD === 'false' && REACT_APP_STAGING === 'false',
        enableSocket: true
      }
    })

    countrClient.setClient({
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET
    })

    countrClient.on('refreshed', async token => {
      try {
        localStorage.setItem('access_token', token.access_token)
        localStorage.setItem('refresh_token', token.refresh_token)
      } catch (error) {
        console.log(error)
      }
    })

    return countrClient
  }

  return {
    getCountrSdk: function () {
      if (!instance) {
        instance = createCountrSdk()
      }

      // Checking if is not connected, than force it to connect again
      instance.then(countr => {
        if (!countr.ws.socket.connected) {
          countr.ws.socket.connect()
        }
      })

      return instance
    },

    /**
     * Slack Channel log error with Countr
     * If we catch an error we try to use Countr Error Collection
     * @params {errorObj} - Object with the information to stringify
     *
     * @return void
     */
    logSlackError: async errorObj => {
      try {
        countrLog.logSlack(
          process.env.REACT_APP_ERROR_SLACK_WEBHOOK,
          `${process.env.NODE_ENV} - ${
            process.env.REACT_APP_ERROR_SOURCE
          } - ${JSON.stringify(errorObj)}`
        )
      } catch (error) {
        const countr = await instance
        countr.e
          .create({
            source: process.env.REACT_APP_ERROR_SOURCE,
            message: `Failed to log on Slack channel - ${process.env.NODE_ENV}`,
            date: new Date().toISOString()
          })
          .catch(console.log)
      }
    },

    /**
     * Log error with Countr
     */
    logError: async errorObj => {
      const countr = await instance

      errorObj.date = new Date().toISOString()
      errorObj.source = process.env.REACT_APP_ERROR_SOURCE

      try {
        countr.e.create(errorObj).catch(error => {
          console.log(error)
        })

        countrLog.logSlack(
          process.env.REACT_APP_ERROR_SLACK_WEBHOOK,
          `${process.env.NODE_ENV} - ${
            process.env.REACT_APP_ERROR_SOURCE
          } - ${JSON.stringify(errorObj)}`
        )
      } catch (error) {
        console.log(error)
      }
    }
  }
})()
