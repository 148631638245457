import { memo, useState } from 'react'
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'

import { Keyboard } from '@countr/ui'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import '../../styles/Shared.scss'

const mapStateToProps = state => {
  return {
    settings: state.settings
  }
}

const CancelOrderPinDialog = connect(
  mapStateToProps,
  null
)(
  memo(function Settings({ open, handleClose, handleCancelOrder, settings }) {
    const [enteredPin, setEnteredPin] = useState('')
    const isCorrectPin = settings?.cancel_order_pin?.value === enteredPin
    const cancelPinSubString = settings?.cancel_order_pin?.value?.substring(
      0,
      enteredPin.length
    )
    const enteredPinClass =
      cancelPinSubString === enteredPin
        ? 'pin-input'
        : 'pin-input cancel-btn-color'

    const confirmBtnClass = isCorrectPin
      ? 'active-cancel-order-btn'
      : 'disabled-cancel-order-btn'

    const handleChange = num => {
      if (num === 'C') {
        setEnteredPin(prevValue => prevValue.slice(0, -1))
      } else {
        setEnteredPin(prevValue => prevValue + num)
      }
    }

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Text id="cancel_order_pin" />
        </DialogTitle>
        <DialogContent>
          <div className={enteredPinClass}>{enteredPin}</div>

          <Keyboard handleChange={handleChange} showDot={false} />

          <Button
            variant="contained"
            className={`confirm-cancel-order-btn ${confirmBtnClass}`}
            onClick={handleCancelOrder}
            disabled={!isCorrectPin}>
            <Text id="confirm" />
          </Button>
        </DialogContent>

        <DialogActions>
          <Button className="cancel-btn-color" onClick={handleClose}>
            <Text id="close" />
          </Button>
        </DialogActions>
      </Dialog>
    )
  })
)

export default CancelOrderPinDialog
