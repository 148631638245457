const DEFAULT = 'default'
const GRANTED = 'granted'
// const DENIED = 'denied'

export default class Utils {
  static printField = (obj, field) => {
    return !!obj && !!obj[field] ? obj[field] : ''
  }

  static detectAdBlock = async () => {
    try {
      const url = 'https://www3.doubleclick.net'
      const response = await fetch(url, {
        method: 'HEAD',
        mode: 'no-cors',
        cache: 'no-store'
      })

      if (response) {
        return false
      }
    } catch (error) {
      return true
    }
  }

  static parseQuery = queryString => {
    var query = {}
    var pairs = (
      queryString[0] === '?' ? queryString.substr(1) : queryString
    ).split('&')

    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=')
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
    return query
  }

  static getNotificationPermission = () => {
    if (!('Notification' in window)) {
      return
    }

    if (Notification.permission === DEFAULT) {
      Notification.requestPermission()
    }
  }

  static showNotification = body => {
    if (!!('Notification' in window) && Notification.permission === GRANTED) {
      new Notification(process.env.REACT_APP_NAME, {
        body,
        icon: 'https://countrhq.com/wp-content/uploads/2016/03/color_tag_H.png'
      })
    }
  }
}
