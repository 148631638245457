import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { translate } from 'react-internationalization'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto'
  },
  item: {
    minWidth: '45%'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}))

function not(a, b) {
  const result = a.filter(a => {
    const i = b.findIndex(b => b._id === a._id)
    return i === -1
  })

  return result
}

function intersection(a, b) {
  const result = a.filter(a => {
    const i = b.findIndex(b => b._id === a._id)
    return i !== -1
  })

  return result
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

/**
 * Category List
 */
const CategoryTransferList = props => {
  const classes = useStyles()
  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const device = useSelector(state => state.device)
  const categories = useSelector(state => state.categories)

  const { setCategories } = props

  const savedCategoryIds = useMemo(
    () => device.device?.settings?.web_settings?.kitchen_categories ?? [],
    [device.device?.settings?.web_settings?.kitchen_categories]
  )

  const savedCategories = useMemo(
    () => categories.list.filter(cat => savedCategoryIds.includes(cat._id)),
    [categories.list, savedCategoryIds]
  )

  const currentSaved = useMemo(
    () => not(categories.list, savedCategories),
    [categories.list, savedCategories]
  )

  useEffect(() => {
    setLeft(currentSaved)
    setRight(savedCategories)

    setCategories(savedCategories)
  }, [currentSaved, savedCategories, setCategories])

  const handleToggle = currentCat => () => {
    const currentIndex = checked.findIndex(cat => cat._id === currentCat._id)

    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(currentCat)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    props.setCategories(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    props.setCategories(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${translate(
          'selected'
        )}`}
      />

      <Divider />

      <List className={classes.list} dense component="div" role="list">
        {items.map(category => {
          const labelId = `transfer-list-all-item-${category._id}-label`

          return (
            <ListItem
              key={category._id}
              role="listitem"
              button
              onClick={handleToggle(category)}>
              <ListItemIcon>
                <Checkbox
                  checked={Boolean(
                    checked.filter(cat => category._id === cat._id).length
                  )}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                className="settings-item-name"
                id={labelId}
                primary={`${category.name}`}
              />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  return (
    <Grid item>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}>
        <Grid item className={classes.item}>
          {customList(translate('all_categories'), left)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.item}>
          {customList(translate('selected'), right)}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CategoryTransferList
