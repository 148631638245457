import { memo } from 'react';
import { Icon } from '@material-ui/core'
import './../../styles/Shared.scss'

const ActionButton = memo(({ label, action }) => {
  return (
    <button className="action-btn" onClick={action}>
      <Icon className="icon">{label}</Icon>
      <span className="text">{label}</span>
    </button>
  )
})

export default ActionButton
