import { useRef, useState } from 'react'
import { translate } from 'react-internationalization'

import CancelOrderPinDialog from './CancelOrderPinDialog'

import Slider from '@material-ui/core/Slider'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const sliderCancelTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: '#db5757'
      },
      track: {
        color: '#e26a6a'
      },
      rail: {
        color: '#f1514a'
      }
    }
  }
})

const CancelOrderButton = ({ handleCancelOrder }) => {
  const [sliderValue, setSliderValue] = useState(0)
  const [showButton, setShowButton] = useState(false)
  const [showPinModal, setShowPinModal] = useState(false)
  const timeout = useRef(null)

  const containerStyles = {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 5
  }

  const handleChange = (_, val) => {
    clearTimeout(timeout.current)
    setSliderValue(val)

    if (val === 10) {
      setSliderValue(0)
      setShowButton(true)
    } else {
      timeout.current = setTimeout(() => {
        setSliderValue(0)
      }, 300)
    }
  }

  // onClick={handleCancelOrder}

  return (
    <div>
      <div style={containerStyles}>
        {translate('cancel_order')}
      </div>

      {showPinModal && (
        <CancelOrderPinDialog
          open={showPinModal}
          handleClose={setShowPinModal.bind(this, false)}
          handleCancelOrder={handleCancelOrder}
        />
      )}

      {showButton ? (
        <button
          className="status_button select_status___cancel"
          onClick={setShowPinModal.bind(this, true)}>
          {translate('cancel')}
        </button>
      ) : (
        <ThemeProvider theme={sliderCancelTheme}>
          <Slider
            value={sliderValue}
            min={0}
            step={0.1}
            max={10}
            onChange={handleChange}
          />
        </ThemeProvider>
      )}
    </div>
  )
}

export default CancelOrderButton
