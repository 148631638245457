import { Routes, Route, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

import { withRouter } from '../wrappers/routeWrappers'

import ErrorBoundary from '../components/ErrorBoundary'

import MainPage from './MainPage'
import LoginPage from './LoginPage'
import StorePage from './StorePage'
import CompletedPage from './CompletedPage'

const mapStateToProps = state => {
  return {
    app: state.app
  }
}

function AppRoutes(props) {
  const isAuth = Object.keys(props.app.user).length ? true : false

  return (
    <div className="root">
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            exact
            path="/store"
            element={isAuth ? <StorePage /> : <Navigate to="/" />}
          />
          <Route
            exact
            path="/main"
            element={isAuth ? <MainPage /> : <Navigate to="/" />}
          />
          <Route
            exact
            path="/completed"
            element={isAuth ? <CompletedPage /> : <Navigate to="/" />}
          />
          <Route
            exact
            path="/login/:embedDevice"
            element={<LoginPage />}
          />
          {/* Not registered route -> redirect to login */}
          <Route path="*" element={isAuth ? <MainPage /> : <Navigate to="/" />} />
        </Routes>
      </ErrorBoundary>
    </div>
  )
}

export default connect(mapStateToProps)(withRouter(AppRoutes))
