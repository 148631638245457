import { Component } from 'react'
import { connect } from 'react-redux'

import { setLoading } from './../store/actions/app'
import { AppInstances } from '../utils/CountrSdk'

const mapDispatchToProps = dispatch => {
  return {
    setLoading: status => dispatch(setLoading(status))
  }
}

const mapStateToProps = state => {
  return {
    app: state.app,
    device: state.device.device
  }
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    this.props.setLoading(false)

    AppInstances.getCountrSdk().then(countr => {
      if (countr) {
        if (typeof countr.logError === 'function') {
          countr.logError({
            message: error.toString(),
            user: this.props.app.user._id,
            store: this.props.device.store,
            device: this.props.device._id,
            date: new Date().toISOString(),
            stack: info.componentStack
          })
        }
      }
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <h2>Error has being sent to Countr and will investigate.</h2>
        </div>
      )
    }

    return this.props.children
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
