import * as ReactDOMClient from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from './theme'
import * as serviceWorker from './serviceWorker'
import { InternationalizationProvider } from 'react-internationalization'
import * as languages from './translations'

import App from './App'

const store = configureStore()
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

const lang =
  localStorage.getItem('KDS:Language') !== null
    ? localStorage.getItem('KDS:Language')
    : 'en'

const rootElement = document.getElementById('root')

const root = ReactDOMClient.createRoot(rootElement)


root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <InternationalizationProvider
          defaultLanguage={lang}
          languages={languages}>
          <App />
        </InternationalizationProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
