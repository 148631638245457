import { memo, useState } from 'react';
import useInterval from './../../utils/useInterval'
import './../../styles/Header.scss'

const getFormattedTime = () => {
  const now = new Date()
  const sec = now.getSeconds()
  const min = now.getMinutes()
  return `${now.getHours()}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
}

const Clock = memo(function Clock() {
  const [ time, setTime ] = useState(getFormattedTime())

  useInterval(() => {
    setTime(getFormattedTime())
  }, 1000)

  return (
    <div className="clock">
      <span>{time}</span>
    </div>
  )
})

export default Clock
