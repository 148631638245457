import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Text, translate } from 'react-internationalization'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AppInstances } from '../../utils/CountrSdk'
import { StoreUtils as StoreUtilsPkg } from '@countr/utils'

import {
  SHOW_TOAST,
  SET_LOADING,
  SET_STORE,
  SET_STORE_ID
} from '../../store/actions/actionTypes'

const { REACT_APP_SERVICE_NAME } = process.env
const kdsServiceName = REACT_APP_SERVICE_NAME || 'kds'

const ChangeStore = React.memo(({ open, handleClose }) => {
  const app = useSelector(state => state.app)
  const device = useSelector(state => state.device.device)
  const dispatch = useDispatch()
  const showToast = useCallback(
    msg => dispatch({ type: SHOW_TOAST, payload: msg }),
    [dispatch]
  )
  const setLoading = useCallback(
    status => dispatch({ type: SET_LOADING, payload: status }),
    [dispatch]
  )

  const setStore = useCallback(
    st => dispatch({ type: SET_STORE, payload: st }),
    [dispatch]
  )
  const setStoreId = useCallback(
    id => dispatch({ type: SET_STORE_ID, payload: id }),
    [dispatch]
  )
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState([])

  const loadStores = useCallback(async () => {
    const countr = await AppInstances.getCountrSdk()

    try {
      const allStores = await countr.stores.read()
      setStores(allStores)
      const selected = device.settings.storesListener
      setSelectedStores(selected)
    } catch (error) {
      showToast(JSON.stringify(error))
    }
  }, [device.settings.storesListener, showToast])

  useEffect(() => {
    loadStores()
  }, [loadStores])

  const close = () => {
    handleClose('store')
  }

  const checkStore = event => {
    const { checked, value } = event.target
    const filteredStore = stores.filter(store => store._id === value)

    if (checked) {
      AppInstances.getCountrSdk().then(countr =>
        StoreUtilsPkg.addOrRemoveStoreServices(
          countr,
          filteredStore[0],
          kdsServiceName
        )
      )
      setSelectedStores([...selectedStores, value])
    } else {
      AppInstances.getCountrSdk().then(countr =>
        StoreUtilsPkg.addOrRemoveStoreServices(
          countr,
          filteredStore[0],
          kdsServiceName,
          true
        )
      )
      setSelectedStores(selectedStores.filter(st => st !== value))
    }
  }

  const save = async () => {
    if (!selectedStores.length) {
      showToast(translate('you_must_select_a_store'))
      return
    }

    setLoading(true)
    setStore(stores.find(st => st._id === selectedStores[0]))
    setStoreId(selectedStores[0])
    localStorage.setItem('KDS:SelectedStore', selectedStores[0])

    const countr = await AppInstances.getCountrSdk()
    const dev = {
      ...device,
      store: selectedStores[0],
      settings: {
        ...device.settings,
        storesListener: selectedStores
      }
    }
    const updatedDevice = await countr.devices
      .update(dev._id, dev)
      .catch(error => {
        setLoading(false)
        showToast(JSON.stringify(error))
        AppInstances.logError({
          message: JSON.stringify(error),
          user: app.user._id,
          store: selectedStores[0],
          device: dev._id
        })
        return
      })

    if (!!updatedDevice) {
      window.location.reload()
    }
  }

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle id="alert-dialog-title">
        <Text id="change_store" />
      </DialogTitle>
      <DialogContent>
        {!stores.length ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress color="primary" size={50} />
          </div>
        ) : (
          <FormControl component="fieldset" margin="none">
            <FormGroup>
              {stores.map(store => (
                <FormControlLabel
                  className="stores-label"
                  key={store._id}
                  control={
                    <Checkbox
                      checked={selectedStores.includes(store._id)}
                      onChange={checkStore}
                      value={store._id}
                    />
                  }
                  label={store.name}
                />
              ))}
            </FormGroup>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          <Text id="close" />
        </Button>
        <Button onClick={save} color="secondary">
          <Text id="save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
})

export default ChangeStore
