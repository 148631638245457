import { memo, useMemo } from 'react';
import { connect } from 'react-redux'
import { Text, translate, setLanguage } from 'react-internationalization'
import { setSettingsItem } from './../../store/actions/settings'
import { setDevice } from '../../store/actions/device'
import { showToast } from './../../store/actions/app'

import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const mapStateToProps = state => {
  return {
    device: state.device
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSettingsItem: setting => dispatch(setSettingsItem(setting)),
    setDevice: settings => dispatch(setDevice(settings)),
    showToast: msg => dispatch(showToast(msg))
  }
}

const STYLE = {
  grid: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: 6
    // borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  one: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  two: {
    flex: 2
  },
  three: {
    flex: 3
  },
  line: {
    minWidth: 300
  },
  changeInput: {
    textAlign: 'right',
    width: '100%'
  },
  inputSize: {
    width: '100%'
  },
  selectStyle: {
    width: '100%',
    textAlign: 'center'
  }
}

const SettingsItem = memo(function SettingsItem({
  settingKey,
  setting,
  setSettingsItem,
  showToast
}) {
  const RANGE = useMemo(
    () =>
      Array.apply(null, { length: setting.max + 1 || 1 })
        .map(Number.call, Number)
        .filter(i => i >= setting.min || 1),
    [setting]
  )

  const handleChange = value => {
    setSettingsItem({ key: settingKey, value })

    if (settingKey === 'language') {
      setLanguage(value)
      localStorage.setItem('KDS:Language', value)
      showToast(translate('languages.restart_msg'))
    }
  }

  const _getItemLabel = option => {
    if (settingKey === 'language') {
      return translate(`languages.${option}`)
    }

    return option
  }

  const _buildSettingInput = setting => {
    if (setting.type === 'string') {
      return (
        <TextField
          style={STYLE.inputSize}
          value={setting.value}
          onChange={$event => handleChange($event.target.value)}
        />
      )
    } else if (setting.type === 'number') {
      return (
        <Select
          value={setting.value}
          style={STYLE.selectStyle}
          onChange={$event => handleChange($event.target.value)}>
          {RANGE.map(num => (
            <MenuItem key={num} value={num}>
              {num === 0 ? translate('off') : num}
            </MenuItem>
          ))}
        </Select>
      )
    } else if (setting.type === 'array') {
      return (
        <Select
          value={setting.value}
          style={STYLE.selectStyle}
          onChange={$event => handleChange($event.target.value)}>
          {setting.options.map(option => (
            <MenuItem key={option} value={option}>
              {_getItemLabel(option)}
            </MenuItem>
          ))}
        </Select>
      )
    } else {
      return (
        <Switch
          checked={setting.value}
          onChange={() => handleChange(!setting.value)}
          color="secondary"
          inputProps={{ 'aria-label': setting.label }}
        />
      )
    }
  }

  return (
    <div style={STYLE.grid}>
      <div style={STYLE.three}>
        <Text id={setting.label} />
      </div>
      <div style={STYLE.one}>{_buildSettingInput(setting)}</div>
    </div>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsItem)
