/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { translate } from 'react-internationalization'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { CartUtils as CountrUtilsPkg } from '@countr/utils'

import { AppInstances } from '../../utils/CountrSdk'
import CountrResources from '../../utils/CountrResources'

import { withRouter } from '../../wrappers/routeWrappers'

import ProductMedia from '../Shared/ProductMedia'
import GroupNote from './GroupNote'
import GroupAddons from './GroupAddons'

import CartUtils from '../../utils/CartUtils'

import { setCurrentGroups } from '../../store/actions/app'

const { getCartEntryId } = require('@countr/sdk/dist/client')

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%'
  },
  media: {
    height: 0,
    width: '100%',
    paddingTop: '56.25%',
    marginBottom: 15
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: '#fff',
    padding: '5px'
  }
}))

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

const mapStateToProps = state => {
  return {
    carts: state.carts,
    device: state.device.device,
    store: state.store.store
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentGroups: groups => dispatch(setCurrentGroups(groups))
  }
}

/**
 * @param  {} {group
 * @param  {} carts
 */
const Group = props => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const groupedEnabled = useSelector(state => state.app.groupedEnabled)

  const dispatch = useDispatch()
  const updateCart = useCallback(
    cart => dispatch({ type: 'UPDATE_CART', payload: { cart } }),
    [dispatch]
  )

  const { show_product_image, show_product_media } = props.device.settings

  const { group } = props
  const { color } = props.group

  const rgb = hexToRgb(color)

  const handleExpandClick = () => setExpanded(!expanded)

  const reselectElements = useCallback(() => {
    return document.querySelectorAll(
      `div.product__${CSS.escape(group.id)}__printed, 
           div.product__${CSS.escape(group.id)}__pending,
           div.product__${CSS.escape(group.id)}__new`
    )
  }, [group.id])

  /**
   * @TODO Move to Countr Utils passing element and color as params
   */
  const paintProducts = useCallback(() => {
    const elementsSelected = reselectElements()

    if (elementsSelected.length) {
      for (let i = 0; i < elementsSelected.length; i++) {
        const _el = elementsSelected[i]
        _el.style.borderLeft = `solid 10px ${color}`
        const qty = _el.querySelector('.qty')
        qty.style.border = `solid 4px ${color}`
        qty.style.color = color
      }
    }
  }, [color, reselectElements])

  /**
   * Unpainted the Groups when user click to close Groups section
   */
  const unpaintedProducts = useCallback(() => {
    const elementsSelected = reselectElements()

    if (elementsSelected.length) {
      for (let i = 0; i < elementsSelected.length; i++) {
        const _el = elementsSelected[i]
        _el.style.borderLeft = ``
        const qty = _el.querySelector('.qty')
        qty.style.border = ``
        qty.style.color = ''
      }
    }
  }, [reselectElements])

  useEffect(() => {
    if (!groupedEnabled) {
      unpaintedProducts()
    } else {
      paintProducts()
    }
  }, [
    groupedEnabled,
    paintProducts,
    unpaintedProducts,
    props.carts.showingList
  ])

  const changeGroupStatus = async (group, status) => {
    const cartsToBeUpdated = []
    const countr = await AppInstances.getCountrSdk()
    props.carts.showingList.forEach(cart => {
      cart.items.forEach(item => {
        if (countr.retrieveCartEntryId(item.product) === group.id) {
          if (status === 'ready') {
            printCart(cart, item.product)
          }

          cartsToBeUpdated.push(cart)
        }
      })
    })

    await CartUtils.computeGroupNextStatus(cartsToBeUpdated, group, status)

    if (cartsToBeUpdated.length) {
      for (let i = 0; i < cartsToBeUpdated.length; i++) {
        const currentCart = cartsToBeUpdated[i]
        currentCart.status = countr.calculateStatus(currentCart.items)
        updateCart(currentCart)
        CountrResources.updateTransaction(currentCart, props.device._id)
      }
    }
  }

  /**
   * @TODO move to PrintUtils to DRY and use here and in Tools.js
   */
  const printCart = async (cart, product) => {
    /**
     * @TODO make a Util FN hasDeviceToPrint
     */
    if (
      props.store?.options?.printDevice ||
      localStorage.getItem('localDesktop')
    ) {
      const type = `${String(cart.__t).toLowerCase()}s`
      const countr = await AppInstances.getCountrSdk()
      const getUniqueId = countr.retrieveCartEntryId(product)

      countr[type].print(cart._id, {
        device:
          localStorage.getItem('localDesktop') ||
          props.store?.options?.printDevice,
        status: 'ready',
        item: getUniqueId
      })
    }
  }

  /**
   * @TODO Move to Countr Utils passing elements and params
   */
  const highlighItems = (group, classname, event, isOut) => {
    const elementsToHighligh = reselectElements()

    for (let i = 0; i < elementsToHighligh.length; i++) {
      const element = elementsToHighligh[i]
      const left = element.querySelector('.left')

      if (element) {
        switch (event) {
          case 'hover':
            left.classList[isOut ? 'remove' : 'add'](classname)
            break

          default:
            left.classList.add(classname)
            // eslint-disable-next-line no-case-declarations
            const scaleInterval = setInterval(() => {
              left.classList.toggle(classname)
            }, 800)

            setTimeout(() => {
              clearInterval(scaleInterval)
            }, 2400)
            break
        }
      }
    }
  }

  return (
    <Card
      key={`${group.id}`}
      id={`${group.id}`}
      className={`grouped-content ${classes.root}`}
      style={Object.assign(
        {},
        {
          backgroundColor: rgb ? `rgb(${rgb.r},${rgb.g},${rgb.b}, 0.5)` : '',
          border: rgb ? `solid 5px rgb(${rgb.r},${rgb.g},${rgb.b}, 0.5)` : ''
        }
      )}>
      <CardHeader
        avatar={
          <Avatar
            aria-label={translate('group_qty')}
            className={`${classes.avatar}`}
            style={Object.assign({}, { color })}>
            {`${group.qty}x`}
          </Avatar>
        }
        action={
          (!!group.notes?.length || !!group.addons?.length) && (
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label={translate('show_more')}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={group.name}
      />
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className="group-card-content"
        style={Object.assign(
          {},
          {
            borderTop: rgb
              ? `solid 3px rgb(${rgb.r},${rgb.g},${rgb.b}, 0.5)`
              : ''
          }
        )}>
        <CardContent>
          {!!group.image?.length && !!show_product_image?.value && (
            <CardMedia
              className={classes.media}
              image={group.image}
              title={group.name}
            />
          )}

          {!!group.media?.length && !!show_product_media?.value && (
            <div className="product-media">
              <h2>
                <span className="icon-image-add"></span>
                {translate('product_media')}
              </h2>
              <ProductMedia medias={group.media} options={{ width: '100%' }} />
            </div>
          )}

          {!!group.notes.length && (
            <GroupNote classes color notes={group.notes} />
          )}

          {!!group.addons.length && (
            <GroupAddons classes color addons={group.addons} />
          )}
        </CardContent>
      </Collapse>

      <CardActions className="group-card-actions" disableSpacing>
        <IconButton
          className="countr-icon"
          onClick={() => changeGroupStatus(group, 'preparing')}
          aria-label={translate('start_preparing')}
          title={translate('start_preparing')}>
          <i className="m-icon icon-hourglass"></i>
        </IconButton>
        <IconButton
          className="countr-icon"
          onClick={() => changeGroupStatus(group, 'ready')}
          title={translate('set_ready_print')}
          aria-label={translate('set_ready_print')}>
          <i className="m-icon icon-alarm"></i>
        </IconButton>
        <IconButton
          className="countr-icon"
          onClick={() => changeGroupStatus(group, 'completed')}
          title={translate('send_completed')}
          aria-label={translate('send_completed')}>
          <i className="m-icon icon-check"></i>
        </IconButton>
        <IconButton
          onMouseEnter={() =>
            highlighItems(group, 'scale-hover', 'hover', false)
          }
          onMouseLeave={() =>
            highlighItems(group, 'scale-hover', 'hover', true)
          }
          className="countr-icon"
          onClick={() => highlighItems(group, 'highlighted')}
          title={translate('highlight_items')}
          aria-label={translate('highlight_items')}>
          <i className="m-icon icon-quantity"></i>
        </IconButton>
      </CardActions>
    </Card>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Group))
