import Avatar from '@material-ui/core/Avatar'
import { translate } from 'react-internationalization'

export default function GroupAddons({ addons, classes, color }) {
  return (
    <>
      <h2>
        <span className="icon-add-ons"></span>
        {translate('addons_per_qty')}
      </h2>
      <ul>
        {addons.map(item => {
          return item.addons.map((addon, j) => {
            return (
              <li key={`${addon.name}_${j}_addon`} className="qty-wrapper">
                <Avatar
                  aria-label={translate('group_qty')}
                  className={`${classes.avatar}`}
                  style={Object.assign({}, { color })}>
                  <span>{item.qty}</span>
                  <small>x</small>
                </Avatar>
                <small className="small-x-qty">
                  {addon.amount}
                  <span>x</span>
                </small>{' '}
                {addon.name}
              </li>
            )
          })
        })}
      </ul>
    </>
  )
}
