import { createStore, combineReducers } from 'redux'
import appReducer from './reducers/app'
import settingsReducer from './reducers/settings'
import storeReducer from './reducers/store'
import cartsReducer from './reducers/carts'
import deviceReducer from './reducers/device'
import categoriesReducer from './reducers/categories'

const rootReducer = combineReducers({
  app: appReducer,
  settings: settingsReducer,
  store: storeReducer,
  carts: cartsReducer,
  device: deviceReducer,
  categories: categoriesReducer
})

const configureStore = () => {
  return createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
}

export default configureStore
