import { SET_STORE_ID, SET_STORE, SET_EMPLOYEES, SET_ALL_ASSIGNED } from './actionTypes'

export const setStoreId = id => ({
  type: SET_STORE_ID,
  payload: id
})

export const setStore = store => ({
  type: SET_STORE,
  payload: store
})

export const setEmployees = employees => ({
  type: SET_EMPLOYEES,
  payload: employees
})

export const setAssignedStores = stores => ({
  type: SET_ALL_ASSIGNED,
  payload: stores
})
