import Avatar from '@material-ui/core/Avatar'
import { translate } from 'react-internationalization'

export default function GroupNote({ notes, color, classes }) {
  return (
    <>
      <h2>
        <span className="icon-note"></span>
        {translate('notes_per_qty')}
      </h2>
      <ul>
        {notes.map((note, i) => {
          return (
            <li key={`${i}_note`} className="qty-wrapper">
              <Avatar
                aria-label={translate('group_qty')}
                className={`${classes.avatar}`}
                style={Object.assign({}, { color })}>
                <span>{note.qty}</span>
                <small>x</small>
              </Avatar>
              {note.note}
            </li>
          )
        })}
      </ul>
    </>
  )
}
