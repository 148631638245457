import { memo, useState } from 'react';
import { Text } from 'react-internationalization'
import { Icon } from '@material-ui/core'
import Utils from '../../utils/Utils'
import './../../styles/cart.scss'

const CustomerDetails = memo(({ cart }) => {
  const [showInfo, setShowInfo] = useState(false)

  const expandCustomerInfo = print => {
    const tags = []
    for (const key in print) {
      // eslint-disable-next-line no-prototype-builtins
      if (print.hasOwnProperty(key)) {
        const element = print[key]
        if (element && element !== '' && typeof element === 'string') {
          tags.push(
            <p key={key}>
              <Text id={key} />: <span>{element}</span>
            </p>
          )
        }
      }
    }

    return tags
  }

  const printCustomerInfo = customer => {
    const fields = ['first_name', 'last_name', 'phone']
    const tags = []

    fields.forEach(field => {
      tags.push(
        <p key={field}>
          <Text id={`customer_information.${field}`} />:{' '}
          <span>{customer[field]}</span>
        </p>
      )
    })

    return tags
  }

  const printCustomerInfoHeader = () => {
    const first_name = Utils.printField(cart.customer, 'first_name')
    const last_name = Utils.printField(cart.customer, 'last_name')
    const mobile = Utils.printField(cart.customer, 'mobile')

    return !!first_name || !!mobile ? (
      <div className="m-l-12">
        <p>{`${first_name}, ${last_name}`}</p>
        <p>{mobile}</p>
      </div>
    ) : (
      <div className="m-l-12">
        <p>
          <Text id="customer_info" />
        </p>
      </div>
    )
  }

  return (
    <div className="customer-details" onClick={() => setShowInfo(!showInfo)}>
      <div className="customer-details-header">
        <Icon>{!showInfo ? 'info' : 'close'}</Icon>
        {printCustomerInfoHeader()}
      </div>

      {!!cart.extras.delivery && !!cart.extras.delivery.print ? (
        <div
          className={`customer-details-content ${showInfo ? 'open' : 'close'}`}>
          {expandCustomerInfo(cart.extras.delivery.print).map(info => info)}
        </div>
      ) : (
        <div
          className={`customer-details-content ${showInfo ? 'open' : 'close'}`}>
          {printCustomerInfo(cart.customer)}
        </div>
      )}
    </div>
  )
})

export default CustomerDetails
