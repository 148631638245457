import {
  MOVE_POSTPONED,
  SET_POSTPONED,
  SET_CARTS,
  UPDATE_CART_BYID,
  SET_CART_TO_TRANSACTION,
  SET_CART_TO_REFUND,
  SET_CANCELLED,
  SET_ALL_CANCELLED,
  TICKET_SOUND,
  COMPLETED_CARTS,
  PRINT_DELIVERY
} from './actionTypes'

export const setCarts = carts => ({
  type: SET_CARTS,
  payload: carts
})

export const movePostponedToList = carts => ({
  type: MOVE_POSTPONED,
  payload: carts
})

export const setPostponed = carts => ({
  type: SET_POSTPONED,
  payload: carts
})

export const setCartToTransaction = cart => ({
  type: SET_CART_TO_TRANSACTION,
  payload: cart
})

export const setCartToRefund = cart => ({
  type: SET_CART_TO_REFUND,
  payload: cart
})

export const updateCartSpecific = cart => {
  return {
    type: UPDATE_CART_BYID,
    payload: { cart }
  }
}

export const setAllCancelled = transactions => ({
  type: SET_ALL_CANCELLED,
  payload: transactions
})

export const setCancelled = transaction => ({
  type: SET_CANCELLED,
  payload: transaction
})

export const setPlayTicketSound = play => ({
  type: TICKET_SOUND,
  payload: play
})

export const setPrintDelivery = info => ({
  type: PRINT_DELIVERY,
  payload: info
})

export const setCompletedCarts = carts => ({
  type: COMPLETED_CARTS,
  payload: carts
})
