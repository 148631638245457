import {
  SET_LOADING,
  SHOW_TOAST,
  DISMISS_TOAST,
  SET_USER,
  SET_PAGE_INDEX,
  SET_MAX_ORDERS_PAGES,
  SET_EMBEDDED_DEVICE,
  SET_CURRENT_GROUPS,
  SET_GROUPED_DEVICE,
  SET_STORES
} from '../actions/actionTypes'

const initialState = {
  status: [
    {
      status: 'printed',
      icon: 'icon-back'
    },
    {
      status: 'preparing',
      icon: 'icon-hourglass'
    },
    {
      status: 'ready',
      icon: 'icon-alarm'
    },
    {
      status: 'completed',
      icon: 'icon-check'
    }
  ],
  user: {},
  isLoading: false,
  showToast: false,
  toastMsg: '',
  pageIndex: 0,
  maxOrdersPages: 1,
  currentEmbeddedDevice: false,
  currentGrouped: {},
  groupedEnabled: false,
  stores: [],
  employees: []
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORES:
      return {
        ...state,
        stores: action.payload
      }
    case SET_GROUPED_DEVICE:
      return {
        ...state,
        groupedEnabled: action.payload
      }
    case SET_EMBEDDED_DEVICE:
      return {
        ...state,
        currentEmbeddedDevice: action.payload
      }

    case SET_CURRENT_GROUPS:
      return {
        ...state,
        currentGrouped: { ...action.payload }
      }

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case SHOW_TOAST:
      return {
        ...state,
        toastMsg: action.payload,
        showToast: true
      }
    case DISMISS_TOAST:
      return {
        ...state,
        toastMsg: '',
        showToast: false
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case SET_PAGE_INDEX:
      return {
        ...state,
        pageIndex: action.payload
      }
    case SET_MAX_ORDERS_PAGES:
      return {
        ...state,
        maxOrdersPages: action.payload
      }
    default:
      return state
  }
}

export default appReducer
