import { memo, useState } from 'react';
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'

import DeviceUtils from '../../utils/DeviceUtils'
import { AppInstances } from '../../utils/CountrSdk'
import { setDevice } from '../../store/actions/device'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import SettingsItem from './SettingsItem'
import CategoryTransferList from './CategoryTransferList'

const mapStateToProps = state => {
  return {
    device: state.device,
    settings: state.settings
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDevice: settings => dispatch(setDevice(settings))
  }
}

const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  memo(function Settings({
    open,
    handleClose,
    device,
    settings,
    setDevice
  }) {
    const [categories, setCategories] = useState([])

    const handleCloseSettings = () => {
      handleClose('settings')
    }

    const saveSettings = () => {
      const dev = { ...device.device }
      dev.settings = {
        ...dev.settings,
        ...settings,
        web_settings: {
          ...dev.settings.web_settings,
          kitchen_categories: categories.map(cat => cat._id)
        }
      }

      if(dev.settings?.printer_ip?.value) 
        localStorage.setItem('printer_ip', dev.settings.printer_ip.value)
      else localStorage.removeItem('printer_ip')

      setDevice(dev)

      AppInstances.getCountrSdk().then(countr => {
        DeviceUtils.countrSdk = countr
        DeviceUtils.updateDeviceSettings(dev)
        handleCloseSettings()
      })
    }

    return (
      <Dialog
        open={open}
        onClose={handleCloseSettings}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen>
        <DialogTitle id="alert-dialog-title">
          <Text id="settings" />
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent="space-evenly" alignItems="center">
            <Grid item xs={12}>
              <Text id="categories" />
            </Grid>
            <Grid item xs={12}>
              <CategoryTransferList setCategories={setCategories} />
            </Grid>
          </Grid>
          {Object.keys(settings).map(settingKey => {
            if(['print_delivery', 'printer_ip'].indexOf(settingKey) >= 0 
              && !localStorage.getItem('isDesktop')) return
            return (
              <SettingsItem
                key={settingKey}
                settingKey={settingKey}
                setting={settings[settingKey]}
              />
            )}
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettings} color="primary">
            <Text id="close" />
          </Button>
          <Button onClick={saveSettings} color="secondary">
            <Text id="save" />
          </Button>
        </DialogActions>
      </Dialog>
    )
  })
)

export default Settings
