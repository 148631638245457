import { connect } from 'react-redux'
import { translate } from 'react-internationalization'

import CancelOrderButton from './CancelOrderButton'

export const StatusButtons = props => {
  return (
    <>
      {props.app.status.map((state, i) => (
        <button
          key={`${i}_${state.status}`}
          className={`status_button select_status___${state.status}`}
          onClick={() => props.updateStatus(state.status)}>
          <i className={`m-icon ${state.icon}`}></i>
          <p>{translate(state.status)}</p>
        </button>
      ))}
      {props.showCancel && (
        <CancelOrderButton
          handleCancelOrder={props.updateStatus.bind(this, 'cancelled')}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StatusButtons)
