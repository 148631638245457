// App actions
export const SET_USER = 'SET_USER'
export const SET_STORES = 'SET_STORES'
export const SET_LOADING = 'SET_LOADING'
export const SHOW_TOAST = 'SHOW_TOAST'
export const DISMISS_TOAST = 'DISMISS_TOAST'
export const SET_PAGE_INDEX = 'SET_PAGE_INDEX'
export const SET_EMBEDDED_DEVICE = 'SET_EMBEDDED_DEVICE'
export const SET_MAX_ORDERS_PAGES = 'SET_MAX_ORDERS_PAGES'
export const SET_CURRENT_GROUPS = 'SET_CURRENT_GROUPS'
export const SET_GROUPED_DEVICE = 'SET_GROUPED_DEVICE'

// Settings actions
export const SET_SETTINGS_ITEM = 'SET_SETTINGS_ITEM'

// STORE actions
export const SET_STORE_ID = 'SET_STORE_ID'
export const SET_STORE = 'SET_STORE'
export const SET_ALL_ASSIGNED = 'SET_ALL_ASSIGNED'

// CARTS actions
export const MOVE_POSTPONED = 'MOVE_POSTPONED'
export const SET_POSTPONED = 'SET_POSTPONED'
export const SET_CARTS = 'SET_CARTS'
export const SET_CART_TO_TRANSACTION = 'SET_CART_TO_TRANSACTION'
export const SET_CART_TO_REFUND = 'SET_CART_TO_REFUND'
export const UPDATE_CART = 'UPDATE_CART'
export const UPDATE_CART_BYID = 'UPDATE_CART_BYID'
export const SET_ALL_CANCELLED = 'SET_ALL_CANCELLED'
export const SET_CANCELLED = 'SET_CANCELLED'
export const TICKET_SOUND = 'TICKET_SOUND'
export const PRINT_DELIVERY = 'PRINT_DELIVERY'
export const SET_SHOWING_CARTS = 'SET_SHOWING_CARTS'
export const COMPLETED_CARTS = 'COMPLETED_CARTS'

// Employees Actions
export const SET_EMPLOYEES = 'SET_EMPLOYEES'

// Device Actions
export const SET_DEVICE = 'SET_DEVICE'
export const SET_DEVICE_SETTINGS = 'SET_DEVICE_SETTINGS'
export const SET_DEVICES = 'SET_DEVICES'

// Categories Actions
export const SET_CATEGORIES = 'SET_CATEGORIES'
