import 'whatwg-fetch'

import { AppInstances } from './CountrSdk'

const DEFAULT_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const ORDER_TYPE = {
  CART: 'Cart',
  TRANSACTION: 'Transaction'
}

export default class CountrResources {
  login = async (username, password) => {
    // Getting countr sdk instace
    const countr = await AppInstances.getCountrSdk()

    // after registered, it will set all tokens
    countr.once('registered', token => {
      localStorage.setItem('token_type', token.token_type)
      localStorage.setItem('access_token', token.access_token)
      localStorage.setItem('refresh_token', token.refresh_token)

      countr.setToken({
        access_token: token.access_token,
        refresh_token: token.refresh_token
      })
    })

    // Performing login
    return countr.register(username, password).then(
      user => {
        return Promise.resolve(user)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  autoLogin = async () => {
    const countr = await AppInstances.getCountrSdk()
    const access_token = localStorage.getItem('access_token')
    const refresh_token = localStorage.getItem('refresh_token')

    countr.setToken({
      access_token: access_token,
      refresh_token: refresh_token
    })

    return countr.me.read().then(
      user => {
        return Promise.resolve(user)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  getResource = async (
    resource,
    limit = process.env.REACT_APP_RESOURCE_LIMIT,
    sort = '-updated_at'
  ) => {
    const countr = await AppInstances.getCountrSdk()

    return countr[resource].read({ limit, sort }).then(
      res => {
        return Promise.resolve(res)
      },
      error => {
        // @TODO LOG TO ERROR LOG
        return Promise.reject(error)
      }
    )
  }

  /**
   * @param  {} type
   * @param  {} countr
   * @param  {} stores
   * @param  {} past_days=0
   */
  getOrders = async (type, countr, stores, past_days = 0) => {
    const copyDate = new Date()
    copyDate.setDate(copyDate.getDate() - past_days)
    const day = copyDate.getUTCDate()
    const dd = day < 10 ? '0' + day : day
    const month = copyDate.getUTCMonth() + 1
    const mm = month < 10 ? '0' + month : month
    const yyyy = copyDate.getUTCFullYear()
    const fixedDate = `${yyyy}-${mm}-${dd}`

    const actives = []
    const completes = []

    const limit = process.env.REACT_APP_RESOURCE_LIMIT
    // const sort = '-updated_at'

    for (let i = 0; i < stores.length; i++) {
      const store = stores[i]
      const result = await countr[type].read({
        filter: {
          date: fixedDate,
          store: store._id
        },
        type: {
          date: 'date',
          store: 'ObjectId'
        },
        operator: {
          date: '$gte',
          store: '$eq'
        },
        limit
      })

      const activeTickets = result.filter(item => {
        return (
          item.status === 'printed' ||
          item.status === 'ready' ||
          item.status === 'pending' ||
          item.status === 'preparing'
        )
      })

      const completedTickets = result.filter(
        item => item.status === 'completed'
      )

      completes.push(...completedTickets)
      actives.push(...activeTickets)
    }

    return { actives, completes }
  }

  static getEmployees = async () => {
    const access_token = localStorage.getItem('access_token')
    const rawResponse = await fetch(
      `${process.env.REACT_APP_API_SERVER}/employees`,
      {
        method: 'GET',
        headers: Object.assign(DEFAULT_HEADER, {
          Authorization: `Bearer ${access_token}`
        })
      }
    )

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      // @TODO LOG TO ERROR LOG
      console.log(content)
    }
  }

  static updateTransaction = async (body, device, isCartOrItem) => {
    //TODO MUST use the SDK and not hardcoded URLs
    const access_token = localStorage.getItem('access_token')
    const type = body.__t === ORDER_TYPE.TRANSACTION ? 'transactions' : 'carts'
    const endpoint = `${process.env.REACT_APP_API_SERVER}/${type}/${
      body._id || body.id
    }/status`

    body.originator = device
    body.extras = {
      ...body.extras,
      isCartOrItem
    }

    const rawResponse = await fetch(endpoint, {
      method: 'PATCH',
      headers: Object.assign(DEFAULT_HEADER, {
        Authorization: `Bearer ${access_token}`
      }),
      body: JSON.stringify(body)
    })

    const content = await rawResponse.json()

    if (content && !content.error) {
      return await content
    } else {
      //TODO LOG TO ERROR LOG
      // ! Really log it and throw in slack maybe?
      console.log(content)
    }
  }
}
