import { SET_DEVICE, SET_DEVICE_SETTINGS, SET_DEVICES } from './actionTypes'

export const setDevice = device => ({
  type: SET_DEVICE,
  payload: device
})

export const setDeviceSettings = settings => ({
  type: SET_DEVICE_SETTINGS,
  payload: settings
})

export const setDevices = devices => ({
  type: SET_DEVICES,
  payload: devices
})