import { memo } from 'react';
import LogoImg from './../../assets/color_tag_H.png'

const logoStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}

const Logo = memo(() => {
  return (
    <div style={logoStyle}>
      <img height="125" alt="header avatar" src={LogoImg} />
    </div>
  )
})

export default Logo
