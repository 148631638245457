import { memo, useEffect, useState, useRef } from 'react'

import { connect } from 'react-redux'

import { AppInstances } from '../utils/CountrSdk'

import { withRouter } from '../wrappers/routeWrappers'

import Group from './Groups/Group'

import './../styles/Grouped.scss'

import { setCurrentGroups } from '../store/actions/app'

const mapStateToProps = state => {
  return {
    app: state.app,
    carts: state.carts,
    device: state.device.device,
    categories: state.categories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentGroups: groups => dispatch(setCurrentGroups(groups))
  }
}

const Grouped = memo(props => {
  const [coloredGroups, setColoredGroups] = useState([])

  const colorRef = useRef({})

  const settingsKitchenCategories =
    props.device?.settings?.web_settings?.kitchen_categories || []

  // Refactor it all, next phase, horrible useEffect doing many responsabilities
  useEffect(() => {
    const preparingTogetherGroups = []
    
    if (props.carts.showingList?.length) {
      AppInstances.getCountrSdk().then(countr => {
        
        props.carts.showingList.forEach(cart => {
          const parseAggregatedItems = cart.items.filter(item => {
            const catIsSet = item.product.categories.findIndex(category =>
              settingsKitchenCategories.includes(category._id)
              || (item.product.report_category?._id 
                && settingsKitchenCategories?.includes(item.product.report_category._id)
              )
            )
  
            const _status = item.status.filter(st => {
              /**
               * TODO MAKE IT VIA SETTINGS,
               * Can we make it configurable? Disappear either on 'ready' or on ticket 'completed'
               */
              if (
                st.amount > 0 &&
                (st.state === 'pending' ||
                  st.state === 'printed' ||
                  st.state === 'preparing' ||
                  st.state === 'ready')
              ) {
                return st
              }
            })
  
            if (_status.length) {
              if (!settingsKitchenCategories.length) {
                return item
              } else if (~catIsSet) {
                return item
              }
            }
          })
  
          for (let i = 0; i < parseAggregatedItems.length; i++) {
            const item = parseAggregatedItems[i]
            const formattedProductId = countr.retrieveCartEntryId(item.product)
            if (!preparingTogetherGroups[formattedProductId]) {
              preparingTogetherGroups[formattedProductId] = [
                // Add more group options and configurations if needed here
                // IDEA to listener for next item do add when times arrives
                // {
                //   timer: {
                //     sliderTimeToCount: 3,
                //     statedTime: +new Date()
                //   }
                // }
              ]
            }
  
            preparingTogetherGroups[formattedProductId].push({
              cartId: cart._id,
              ...item,
              created_at: +new Date()
            })
          }
        })
  
        if (!Object.keys(preparingTogetherGroups).length) {
          props.setCurrentGroups({})
        } else {
          props.setCurrentGroups(preparingTogetherGroups)
        }
  
        calculateGroups(preparingTogetherGroups)
      })
    }

    // !FIX ME PLEASE, please :(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.carts.showingList, settingsKitchenCategories])

  /**
   * Generate Groups with all data needed to list
   * @param  {} groups
   */
  async function calculateGroups(groups) {
    const groupsObjects = []

    const countr = await AppInstances.getCountrSdk()

    for (const i in groups) {
      if (Object.hasOwnProperty.call(groups, i)) {
        const group = groups[i]

        const notes = []
        const addons = []

        for (let j = 0; j < group.length; j++) {
          const item = group[j]
          const note = item.note ? item.note.trim() : null
          if (note && note.length) {
            notes.push({
              note,
              qty: item.amount
            })
          }

          if (item.product?.current_addons?.length) {
            addons.push({
              addons: item.product.current_addons,
              qty: item.amount
            })
          }
        }

        /**
         * @TODO DRY IT with the Above method
         */
        const qty = group.reduce((crr, acc) => {
          const statusQty = acc.status.reduce((stacc, st) => {
            if (
              st.amount > 0 &&
              (st.state === 'pending' ||
                st.state === 'printed' ||
                st.state === 'preparing' ||
                st.state === 'ready')
            ) {
              stacc += st.amount
            }
            return stacc
          }, 0)

          return (crr += statusQty)
        }, 0)

        const product = group[0].product

        if (!product.options?.auto_created) {
          groupsObjects.push({
            notes,
            addons,
            qty,
            name: product.name,
            color: product.color,
            image: product.image,
            media: product.media,
            id: countr.retrieveCartEntryId(product),
            orders: group.map(info => {
              return {
                id: info.cartId,
                order_qty: info.amount
              }
            })
          })
        }
      }
    }

    if (groupsObjects.length) {
      // Set the group to the local state for rendering list
      setColoredGroups(groupsObjects)
    } else {
      // Or empty as no groups
      setColoredGroups([])
    }
  }

  return (
    <div className="grouped" id="grouped">
      {coloredGroups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group, i) => {
          return (
            <Group
              key={`group_${i}`}
              group={group}
              colorRefeferences={colorRef}
            />
          )
        })}
    </div>
  )
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Grouped))
