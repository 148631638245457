import {
  SET_STORE_ID,
  SET_STORE,
  SET_EMPLOYEES,
  SET_ALL_ASSIGNED
} from '../actions/actionTypes'

const initialState = {
  id: '',
  store: {},
  employees: [],
  allStoresAssigned: []
}

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_ID:
      return {
        ...state,
        id: action.payload
      }
    case SET_STORE:
      return {
        ...state,
        store: action.payload
      }
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload
      }
    case SET_ALL_ASSIGNED:
      return {
        ...state,
        allStoresAssigned: action.payload
      }
    default:
      return state
  }
}

export default storeReducer
