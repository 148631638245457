import { memo } from 'react';
import { connect } from 'react-redux'
import StatusInfoCart from '../components/Shared/StatusInfoCart'
import './../styles/App.scss'

const COMPLETED = 'completed'
const mapStateToProps = state => {
  return {
    carts: state.carts
  }
}

const CompletedPage = memo(props => {
  return (
    <div id="main">
      <div className="completed-screen-grid">
        {props.carts.completedList.map(
          cart =>
            cart.status === COMPLETED && (
              <StatusInfoCart key={cart._id} cart={cart} type={COMPLETED} />
            )
        )}
      </div>
    </div>
  )
})

export default connect(mapStateToProps, null)(CompletedPage)
